<template>
    <div class="columns is-centered">
        <div class="column is-three-quarters-desktop is-full-touch">
            <enso-form class="box has-background-light raises-on-hover" ref="ruleForm"
                       :path="route('rule.clone', $route.params.rule, false)">
                <template #gives_points="props">
                    <form-field has-tooltip-right v-bind="props"
                                @selection="toggleGivesPoints"/>
                </template>
                <template #by_rank="props">
                    <form-field has-tooltip-right v-bind="props"
                                @selection="toogleByRank"/>
                </template>
                <template #replicate="props">
                    <form-field has-tooltip-right v-bind="props"
                                @selection="toogleByReplicate"/>
                </template>
            </enso-form>
        </div>
    </div>
</template>

<script>
import { EnsoForm, FormField } from '@enso-ui/bulma';

export default {
    name: 'Clone',

    components: { EnsoForm, FormField },

    inject: ['route', 'http', 'i18n', 'errorHandler'],

    data() {
        return {
            ready: false,
        };
    },
    computed: {
        form() {
            return this.ready
                ? this.$refs.ruleForm
                : {};
        },
    },
    mounted() {
        this.ready = true;
    },
    methods: {
        submitForm() {
            this.http.post(this.route('rule.store', { rule: this.$route.params.rule }), {
                variable_id: this.rule.variable_id,
                name: this.rule.name,
                load_frequency: this.rule.load_frequency,
                liquidation_frequency: this.rule.liquidation_frequency,
                min: this.rule.min,
                max: this.rule.max,
                points: this.rule.points,
                dependent: this.rule.dependent,
            })
                .then(() => {
                    this.$router.push({ name: 'rule.index' });
                })
                .catch(this.errorHandler);
        },
        toggleGivesPoints(givesPoints) {
            if (!givesPoints) {
                console.error('gives Points object is null or undefined');
                return;
            }
            if (this.$refs.ruleForm && this.$refs.ruleForm.field) {
                if (givesPoints.value !== null && givesPoints.value === true) {
                    this.$refs.ruleForm.field('by_rank').meta.hidden = false;
                } else if (givesPoints.value !== null && givesPoints.value === false) {
                    this.$refs.ruleForm.field('by_rank').meta.hidden = true;
                }
            } else {
                console.error('ruleForm or ruleForm.field is not defined');
            }
        },
        toogleByRank(byRank) {
            if (!byRank) {
                console.error('byRank object is null or undefined');
                return;
            }
            if (this.$refs.ruleForm && this.$refs.ruleForm.field) {
                if (byRank.value !== null && byRank.value === true) {
                    this.$refs.ruleForm.field('initial_value').meta.hidden = false;
                    this.$refs.ruleForm.field('final_value').meta.hidden = false;
                    this.$refs.ruleForm.field('points').meta.hidden = false;
                    this.$refs.ruleForm.field('value').meta.hidden = true;
                } else if (byRank.value !== null && byRank.value === false) {
                    this.$refs.ruleForm.field('value').meta.hidden = false;
                    this.$refs.ruleForm.field('points').meta.hidden = false;
                    this.$refs.ruleForm.field('initial_value').meta.hidden = true;
                    this.$refs.ruleForm.field('final_value').meta.hidden = true;
                }
            } else {
                console.error('ruleForm or ruleForm.field is not defined');
            }
        },
        toogleByReplicate(replicate) {
            if (!replicate) {
                console.error('replicate object is null or undefined');
                return;
            }
            if (this.$refs.ruleForm && this.$refs.ruleForm.field) {
                if (replicate.value !== null && replicate.value === true) {
                    this.$refs.ruleForm.field('profiles_replicate').meta.hidden = false;
                } else if (replicate.value !== null && replicate.value === false) {
                    this.$refs.ruleForm.field('profiles_replicate').meta.hidden = true;
                }
            } else {
                console.error('ruleForm or ruleForm.field is not defined');
            }
        },
    },

};
</script>

<style lang="scss" scoped>
.button is-success{
  display: none !important;
}
</style>
